// gameSlice.js
import { createSlice } from '@reduxjs/toolkit';

const gameSlice = createSlice({

  name: 'game',
  initialState: {
    isTouching: false,
    gameHasStarted: false,
    //boardSize: localStorage.getItem('boardSize') || "2", // Get the board size from local storage, or use a default value
  },
  reducers: {
    setIsTouching: (state, action) => {
      state.isTouching = action.payload;
    },
    setGameHasStarted: (state, action) => {
      state.gameHasStarted = action.payload;
      console.log("gameSlice.js.setGameHasStarted=", action.payload);
    },

  }
});

export const { setIsTouching, setGameHasStarted, setBoardSize } = gameSlice.actions;

export default gameSlice.reducer;
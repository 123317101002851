import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ConfigHeader = () => {
  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  console.log("ConfigHeader.js constructor gameHasStarted=", gameHasStarted);
  useEffect(() => {
    if (gameHasStarted) {
      // Call the function for when gameHasStarted is true
      console.log("ConfigHeader.useEffect 1 gameHasStarted=", gameHasStarted);
    } else {
      console.log("ConfigHeader.useEffect 2 gameHasStarted=", gameHasStarted);
    }
  }, [gameHasStarted]);

  return (
    <div id="header">
        <div className="header-content">
          <div className="spacer">&nbsp;</div>
          <div id="title"><img src="./assets/julije_title.png" alt="Julije Knifer - The Game"/></div>
          <div className="spacer">&nbsp;</div>
        </div>
      </div>
  );
};

export default ConfigHeader;
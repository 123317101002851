//import React from 'react';

function Cell({ y, x, cell, size, handleMouseOver, handleMouseClick, handleMouseLeave }) {
  //console.log("Cell.js.Cell.y=", y, "x=", x, "cell=", cell, "size=", size);
  return (
    <rect 
      className={`rectangle-${y}-${x}`}
      x={x * size - 1} 
      y={y * size - 1} 
      width={size + 2} 
      height={size + 2} 
      onMouseOver={handleMouseOver} 
      onClick={handleMouseClick} 
      onMouseLeave={handleMouseLeave} 
      fill={cell ? 'white' : 'white'}
    />
  );
}

export default Cell;
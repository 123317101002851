// LandingPage.js
import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGameHasStarted } from '../../data/gameSlice';
import LandingHeader from '../elements/LandingHeader';
import LandingFooter from '../elements/LandingFooter';
//import { useNavigate } from 'react-router-dom';
import gsap from "gsap";
//import { Power2, Elastic, Back, Bounce } from 'gsap';
import Board from '../Board';

const LandingPage = () => {
  const dispatch = useDispatch();
  //const container = useRef();
  //dispatch(setGameHasStarted(false));

  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  console.log("LandingPage.js constructor gameHasStarted=", gameHasStarted);

  //const navigate = useNavigate();

  const hideCilinder = () => {
    console.log("LandingPage.js.hideCilinder");
    let cilinderName = `.cilinder`;
    let myDelay = 0.2;// + Math.random();
    let myDuration = 0.5;// + Math.random();
    
    gsap.to(cilinderName, { alpha: 0, duration: myDuration, delay: myDelay });
    gsap.to(cilinderName, { scale: 0.2, duration: myDuration, delay: myDelay });
  }

  const startGame = () => {
    console.log("LandingPage.js.startGame");
    dispatch(setGameHasStarted(true));

    //navigate('/config');

    
  };

  useEffect(() => {
    if (gameHasStarted) {
      // Call the function for when gameHasStarted is true
      console.log("LandingPage.useEffect 1 gameHasStarted=", gameHasStarted);
      hideCilinder();
    } else {
      console.log("LandingPage.useEffect 2 gameHasStarted=", gameHasStarted);
    }
  }, [gameHasStarted]);
  
  // <Board 
  //    container={container}          
  //  />
  return (
      <div id="container">
        
        <LandingHeader />

        {gameHasStarted ? (
          <Board />
        ) : (
          <div id="main">
            <div id="matrix-container" >
              <img 
                className="cilinder" 
                src="./assets/julije_logo_transparent.png" 
                alt="Julije Cilinder" 
                onClick={startGame}
              />
            </div>
          </div>
        )}

        <LandingFooter />
        
      </div>
  );
};

export default LandingPage;
/**
 * @fileoverview This file defines the Board class, which represents the game board in the Julije game.
 * The Board class is responsible for managing and rendering the game state on the game board.
 * It is a crucial part of the game logic, as it handles player moves, checks for win conditions, and updates the game state.
 *  
 * @author Akos Papp
 * @requires Game.js, Player.js
 */


// The Board.js is used to create the game board
// The input is the board configuration
// The output is the game board
// The function is used in the Game component
// The function is used in the Cell component
// The function is used in the RandomBoard component

//import { useRef } from "react";
import gsap from "gsap";
import Cell from './Cell';
import { useDispatch, useSelector } from 'react-redux';
//import { setGameHasStarted } from '../data/gameSlice';
import { setClickBoard, createNewBoard } from '../data/boardSlice';
import React, { useEffect } from 'react';

//import { useDispatch } from 'react-redux';

//import { useGSAP } from "@gsap/react";
//import gsap from "gsap";
//import { useGSAP } from "@gsap/react";

//let gameHasStarted = false;
//let randomBoard;
//let clickBoard;
//gsap.registerPlugin(useGSAP);



/**
 * Description of the function/method
 * @param {type} paramName - Description of the parameter
 * @returns {type} - Description of the return value
 * @throws {type} - Description of the exceptions
 */

/**
 * Initializes the game board when the game starts. It sets up a 2D array 'clickBoard' based on the size of 'randomBoard'.
 * Each cell in 'clickBoard' is initialized to 0. The function also sets 'gameHasStarted' to true.
 * If the game has already started (i.e., 'gameHasStarted' is true), the function does nothing.
 * @returns {void}
 */
const StartGame = (randomBoard, clickBoard) => {
  const dispatch = useDispatch();
  let gameHasStarted = useSelector(state => state.game.gameHasStarted);
  if (gameHasStarted) {
    return;
  }
  let myBoardSize = randomBoard.length;
  clickBoard = new Array(myBoardSize);
  for (let y = 0; y < myBoardSize; y++) {
    clickBoard[y] = new Array(myBoardSize);
    for (let x = 0; x < myBoardSize; x++) {
      //console.log ("Game(Game.js).startGame RandomBoard - ", y, ",", x, " = ", randomBoard[y][x]);
      clickBoard[y][x] = 0;
    }
  }
  console.log('Game(Game.js).startGame - The game has started');
  //dispatch(setGameHasStarted(true));
}

/**
 * Initializes the game board with the provided configuration.
 * It sets the 'randomBoard' to 'myRandomBoard' and defines two event handlers: 'handleMouseOver' and 'handleMouseLeave'.
 * 'handleMouseOver' changes the background color of a cell to black when the mouse hovers over it, if the game has started and the cell hasn't been clicked yet.
 * 'handleMouseLeave' is not fully shown in the provided code, but it presumably handles the event when the mouse leaves a cell.
 * @param {Array} myRandomBoard - The configuration for the game board, presumably a 2D array representing the initial state of the board.
 * @returns {void}
 */
function Board(container) {
  const dispatch = useDispatch();
  console.log("Board.js.Board constructor");
  //const dispatch = useDispatch();
  
  //const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  //console.log("Board.js.Board.gameHasStarted=", gameHasStarted);
  const showHint = useSelector(state => state.board.showHint);

  const randomBoard = useSelector(state => state.board.randomBoard);
  const gameCount = useSelector(state => state.board.gameCount);
  //console.log("Board.js.Board.randomBoard=", randomBoard);

  const boardSize = useSelector(state => state.board.boardSize);
  //console.log("Board.js.Board.boardSize=", boardSize);

  // For actions, you can use the useDispatch hook
  //const dispatch = useDispatch();
  //const clickBoard = (params) => dispatch(state.board.clickBoard(params));
  const clickBoard = useSelector((state) => state.board.clickBoard);
  //console.log("Board.js.Board.clickBoard=", clickBoard);

  let blockUI = false;

  const levelOver = () => {
    //window.location.href = '/done';
    //dispatch(setGameHasStarted(false));
    for (let y = 0; y < clickBoard.length; y++) {
      for (let x = 0; x < clickBoard[y].length; x++) {
        if (clickBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          gsap.to(cellName, { rotation:0, transformOrigin:"50% 50%", duration: 0});
          gsap.to(cellName, { scale:1, duration: 0});
          gsap.to(cellName, { alpha:1, duration: 0});
        }
      }
    }
    dispatch(createNewBoard());
  };

  function destroyTheBoard() {
    console.log('Game(Game.js).destroyTheBoard - The game has ended');
    blockUI = true;
    let maxDelay = 0;
    let effectDuration = 0.3;
    for (let y = 0; y < clickBoard.length; y++) {
      for (let x = 0; x < clickBoard[y].length; x++) {
        if (clickBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = Math.random(); // random delay between 0 and 1
          if (myDelay > maxDelay) {
            maxDelay = myDelay;
          }
          //gsap.to(cellName, { fill: "#ffffff", duration: 0.3, delay: myDelay });
          gsap.to(cellName, { alpha: 0, duration: effectDuration, delay: myDelay });
          gsap.to(cellName, { rotation:90, transformOrigin:"50% 50%", duration: effectDuration, delay: myDelay });
          gsap.to(cellName, { scale:0, duration: effectDuration, delay: myDelay });
        }
      }
    }
    gsap.delayedCall((effectDuration + maxDelay), levelOver);
  }

  /**
   * Checks the current state of the game to determine if the game has been won.
   * The game is considered won if the 'clickBoard' matches the 'randomBoard'.
   * If the game is won, it sets 'gameWon' to true, 'gameHasStarted' to false, and schedules the 'levelOver' function to be called after a delay of 1.5 seconds.
   * If the game is not won, it logs a message and returns early.
   * @returns {void}
   */
  const CheckGame = () => {
    //console.log('Board(Board.js).CheckGame - Checking the game');
    //let gameHasStarted = useSelector(state => state.game.gameHasStarted);
    let gameWon = false;
    //console.log('Board(Board.js).CheckGame - clickBoard = ' + clickBoard.toString());
    if ((clickBoard.toString() === randomBoard.toString())) {
      gameWon = true;
      //gameHasStarted = false;
      //dispatch(setGameHasStarted(false));
    } else {
      console.log('not yet!!! - ' + gameWon);
      return;
    }

    console.log('Game won!!! - ' + gameWon);
    destroyTheBoard();
  }

  //randomBoard = myRandomBoard;
  //clickBoard = myClickBoard;
  //console.log("Board.js.Board.myRandomBoard=", randomBoard);
  //console.log("Board.js.Board.gameHasStarted=", gameHasStarted);
  //console.log("Board.js.Board.gameState.gameHasStarted=", gameState.gameHasStarted);
  //console.log("Board.js.Board.myBoardSize=", myBoardSize);  
  //const container = useRef();

  const handleMouseOver = (e, y, x, clickBoardValue) => {
    if (blockUI) {
      return;
    }
    //let classes = e.target.className.split(' ');
    //let cellName = classes[1];
    //console.log('Mouse over on cell - ', cellName);
    if (clickBoardValue === 0){
      gsap.to(e.target, { fill: "#000000", duration: 0.1 });
    }
  };
  
  const handleMouseLeave = (e, y, x, clickBoardValue) => {
    if (blockUI) {
      return;
    }
    if (clickBoardValue === 1) {
      gsap.to(e.target, { backgroundColor: "#00000"});
    } else {
      gsap.to(e.target, { fill: "#ffffff", duration: 0.1 });
    }
  };
  
  const handleMouseClick = (e, y, x, clickBoard) => {
    if (blockUI) {
      return;
    }
    //console.log('Board.js - handleMouseClick clickBoard y= ' + y + ", x=", x);
    //console.log('Board.js - handleMouseClick clickBoard = ' + clickBoard[y][x] );
    //let classes = e.target.className.split(' ');
    //let cellName = classes[1];
    if (clickBoard[y][x] === 1 ) {
      //gsap.to(e.target, { backgroundColor: "#000000", duration: 0.01});
      //clickBoard[y][x]  = 0;
      dispatch(setClickBoard({ y: y, x: x, value: 0 }));
      gsap.to(e.target, { fill: "#ffffff"});
    } else {
      dispatch(setClickBoard({ y: y, x: x, value: 1 }));
      gsap.to(e.target, { fill: "#000000"});
    }
    //let cellName = "cell-" + y + "-" + x;
    //console.log('Board.js - handleMouseClick clickBoard = ' + clickBoard[y][x] );
    //CheckGame();
  };
  //const cellRef = useRef();

  function hideBoard() {
    for (let y = 0; y < randomBoard.length; y++) {
      for (let x = 0; x < randomBoard[y].length; x++) {
        let cellName = `.rectangle-${y}-${x}`;
        let myDelay = 0;// + Math.random();
        let myDuration = 0.3;// + Math.random();

        if (clickBoard[y][x] === 1) {
          //gsap.to(cellName, { rotation: "+=360", duration: myDuration, delay: myDelay });
          gsap.to(cellName, { fill: "#000000", duration: 0, delay: 0 });
        } else {
          gsap.to(cellName, { fill: "#ffffff", duration: myDuration, delay: myDelay });
        }
      }
    }
    blockUI = false;
  }

  function showRandomBoard() {
    blockUI = true;
    for (let y = 0; y < randomBoard.length; y++) {
      for (let x = 0; x < randomBoard[y].length; x++) {
        let cellName = `.rectangle-${y}-${x}`;
        let myDelay = 0;// + Math.random();
        let myDuration = 0.3;// + Math.random();

        if (randomBoard[y][x] === 1) {
          //gsap.to(cellName, { rotation: "+=360", duration: myDuration, delay: myDelay });
          gsap.to(cellName, { fill: "#000000", duration: myDuration, delay: myDelay });
          gsap.to(cellName, { rotation: 0, duration: 0, delay: 0 });
        } else {
          gsap.to(cellName, { fill: "#ffffff", duration: 0, delay: 0 });
          gsap.to(cellName, { rotation: 0, duration: 0, delay: 0 });
        }
      }
    }
  }
  useEffect(() => {
    if (showHint){
      showRandomBoard();
      // Call hideBoard after a delay of 1 second
    } else {
      hideBoard();
    }
  }, [showHint]);

  useEffect(() => {
    console.log("Board.js.Board.useEffect boardSize="+ boardSize +"********************");
    console.log("Board.js.Board.useEffect gameCount="+ gameCount);
    
  //let boardDimension = 0;//600;
    let cellMargin = 0;
    let boardDimension =  window.innerWidth / 2;

    //if (window.innerWidth < window.innerHeight) {
    //  boardDimension =  window.height / 2;
    //}

    let cellDimension = Math.round((boardDimension + (2 * cellMargin)) / boardSize);

    document.documentElement.style.setProperty('--board-dimension', boardDimension + 'px');
    document.documentElement.style.setProperty('--cell-margin', cellMargin + 'px');
    document.documentElement.style.setProperty('--cell-dimension', cellDimension + 'px');
    document.documentElement.style.setProperty('--header-height', 120 + 'px');
    document.documentElement.style.setProperty('--footer-height', 120 + 'px');

    showRandomBoard();
      // Call hideBoard after a delay of 1 second
    gsap.delayedCall(3, hideBoard);
  }, [randomBoard]);

  CheckGame();
/**
 * Represents the user interface of the game board.
 * It maps over each column in 'randomBoard', creating a new row for each column.
 * Each row contains a number of 'Cell' components equal to the number of cells in the column.
 * Each 'Cell' component is passed the cell value, size in pixels, its coordinates (y and x), and three event handlers: 'handleMouseOver', 'handleMouseClick', and 'handleMouseLeave'.
 * @type {JSX.Element[]}
 */
  const boardUI = randomBoard.map((column, y) => {
    //console.log("Board.js.Board.boardUI.randomBoard.map column=", column);
    //console.log("Board.js.Board.boardUI.randomBoard.map y=", y);
    var w = Math.min(window.innerHeight - 240, window.innerWidth); // width of the svg
    var n = randomBoard.length; // size of the matrix
    //var size = Math.round(w / n) + 4; // size of each rectangle
    var size = w / n; // size of each rectangle

    return column.map((cell, x) => {
      //console.log("Board.js.Board.boardUI.column.map cell=", cell);
      //console.log("Board.js.Board.boardUI.column.map x=", x);

      return (        
        <Cell
          key={`${y}-${x}`}
          y={y} 
          x={x} 
          cell={cell} 
          size={size}  
          handleMouseOver={(e) => handleMouseOver(e, y, x, clickBoard[y][x])}
          handleMouseClick={(e) => handleMouseClick(e, y, x, clickBoard)} 
          handleMouseLeave={(e) => handleMouseLeave(e, y, x, clickBoard[y][x])}
        />
      );
    });
  });

  //gsap.delayedCall(3.5, StartGame, randomBoard, clickBoard);

  //return boardUI;
//<div id="matrix-container" ref={container}>
  return (
    <div id="main">
      <div id="matrix-container">
          <svg id="matrix">{boardUI}</svg>
      </div>
    </div>
  )
}

export default React.memo(Board);;
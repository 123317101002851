import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setIsTouching, setGameHasStarted, setBoardSize } from '../data/gameSlice';

//import { useDispatch, useSelector } from 'react-redux';
//import { setBoardSize } from '../data/gameSlice';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import './Game.css';
import Board from './Board';
import RandomBoard from '../utils/RandomBoard';
import HeaderGame from './elements/HeaderGame';
import FooterGame from './elements/FooterGame';
//const { useRef } = React;
//console.log(React.version);

/**
 * @fileoverview The Game.js file defines the Game component, which is responsible for rendering and controlling the game board. 
 * It retrieves the board size from a cookie and updates it for each new game. 
 * It also calculates the dimensions of the game board and each cell based on the board size. 
 * The Game component is crucial for the game logic and user interaction with the game.
 * @author Akos Papp
 * @requires react, js-cookie, gsap, @gsap/react, Board, RandomBoard, HeaderGame, FooterGame
 */
function Game() {
  const dispatch            = useDispatch();
  //const isTouching          = useSelector((state) => state.game.isTouching);
  //const gameHasStarted      = useSelector((state) => state.game.gameHasStarted);
  const boardSize           = useSelector((state) => state.game.boardSize);

  const container           = useRef();
  const boardSizeMin        = 2;
  const boardSizeMax        = 6;
  
  console.log("Game(Game.js).boardSize=", boardSize);

  if (!boardSize || isNaN(boardSize)) {
    dispatch(setBoardSize(boardSizeMin));
  } else if (boardSize >= (boardSizeMax)) {
    dispatch(setBoardSize(2));
  } else {
    dispatch(setBoardSize(boardSize+1));
  }
  //Cookies.set('boardSize', useSelector((state) => state.game.boardSize));
  //console.log("Game(Game.js).boardSize=", boardSize);
  //let boardSize = Math.floor(Math.random() * (max - min + 1)) + min;
    // Set the boardSize in the Redux store
  //dispatch(setBoardSize(boardSize));

  //let boardDimension = 0;//600;
  let cellMargin = 0;
  let boardDimension =  window.innerWidth / 2;

  //if (window.innerWidth < window.innerHeight) {
  //  boardDimension =  window.height / 2;
  //}

  let cellDimension = (boardDimension + (2 * cellMargin)) / boardSize;

  //console.log("Game(Game.js).boardSize=", boardSize);

  const randomBoard = RandomBoard (boardSize);
  //let myBoardSize = randomBoard.length;
  const clickBoard = new Array(boardSize);
  for (let y = 0; y < boardSize; y++) {
    clickBoard[y] = new Array(boardSize);
    for (let x = 0; x < boardSize; x++) {
      //console.log ("Game(Game.js).startGame RandomBoard - ", y, ",", x, " = ", randomBoard[y][x]);
      clickBoard[y][x] = 0;
    }
  }
  
  useGSAP(() => {
      const observer = new ResizeObserver(entries => {
          //console.log('Game(Game.js).ResizeObserver - size: ', window.innerWidth, 'x', window.innerHeight);
          boardDimension =  window.innerWidth / 2;
          cellDimension = (boardDimension + (2 * cellMargin)) / boardSize;
          //container.current.style.setProperty('--board-dimension', boardDimension + 'px');
          //container.current.style.setProperty('--cell-dimension', cellDimension + 'px');
      });

      if (container.current) {
        observer.observe(container.current);
        //console.log('Game(Game.js).if (container.current) - container.current: ', container.current);
      }

      return () => {
        if (container.current) {
          observer.unobserve(container.current);
        }
      };
    }, 
    { scope: container.current }
  );
  useGSAP(() => {
    //console.log('Game(Game.js).useGSAP - container.current: ', container.current);
    container.current.style.setProperty('--board-dimension', boardDimension + 'px');
    container.current.style.setProperty('--cell-margin', cellMargin + 'px');
    container.current.style.setProperty('--cell-dimension', cellDimension + 'px');
    container.current.style.setProperty('--header-height', 120 + 'px');
    container.current.style.setProperty('--footer-height', 120 + 'px');

    //console.log('The board has been mounted');
    
    for (let y = 0; y < boardSize; y++) {
      //console.log(randomBoard[y].toString())
      for (let x = 0; x < boardSize; x++) {
        //console.log ("RandomBoard - ", y, ",", x, " = ", myBoard [x][y]);
        //randomBoard[y][x] = Math.round(Math.random());
        if (randomBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = 3;// + Math.random();
          let myDuration = 0.3;// + Math.random();
          //gsap.to(cellName, { rotation: "+=360", duration: myDuration, delay: myDelay });
          gsap.to(cellName, { fill: "#ffffff", duration: myDuration, delay: myDelay });
        }
        //console.log ("RandomBoard - ", y, ",", x, " = ", myBoard [y][x]);
      }
    }
  }, [container]); // <-- scope for selector text (optional)

  return (
    <div id="container">
      <HeaderGame />

      <Board 
        container={container}
      />
        {/* 
        randomBoard=randomBoard 
        clickBoard=clickBoard
        */}
      <FooterGame randomBoard={randomBoard} clickBoard={clickBoard} />
      
    </div>
  );
}

export default React.memo(Game);
// GameOverPage.js
import React from 'react';

const GameOverPage = () => {
  const playTheGame = () => {
    window.location.href = '/game';
  };

  return (
    <div>
      <h1>Game Over!</h1>
      <button onClick={playTheGame}>Play again!</button>
    </div>
  );
};

export default GameOverPage;
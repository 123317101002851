import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import gsap from 'gsap'; // Import the 'gsap' library
import { Power2, Elastic, Back, Bounce } from 'gsap';

const LandingHeader = () => {
  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  console.log("LandingHeader.js constructor gameHasStarted=", gameHasStarted);

  const wombleTitle = () => {
    console.log("LandingHeader.js.wombleTitle");
    let titleName = `#title`;
    let myDelay = 0;// + Math.random();
    let myDuration = 0.4;// + Math.random();
    
    //gsap.to(titleName, { alpha: 0, duration: myDuration, delay: myDelay });
    // Create a timeline
    let tl = gsap.timeline();
   // gsap.to(titleName, { x: 5, duration: myDuration, delay: myDelay })

    // Add animations to the timeline
    tl.to(titleName, { scale: 1.5, duration: 4 * myDuration, delay: myDelay, ease: Elastic.easeOut.config(1, 0.3)  }) // Animation 1
    //.to(titleName, { scale: 0.5, duration: myDuration }) // Animation 2
    //.to(titleName, { scale: 1.1, duration: myDuration }) // Animation 3
    .to(titleName, { scale: 1, duration: 4 * myDuration, ease: Elastic.easeOut.config(1.5, 0.3) }); // Animation 4

    //gsap.to(titleName, {duration: 1, drawSVG: "20% 80%"})
  }

  useEffect(() => {
    if (gameHasStarted) {
      // Call the function for when gameHasStarted is true
      console.log("LandingHeader.useEffect 1 gameHasStarted=", gameHasStarted);
      //wombleTitle();
    } else {
      console.log("LandingHeader.useEffect 2 gameHasStarted=", gameHasStarted);
    }
  }, [gameHasStarted]);

  return (
    <div id="header">
        <div className="header-content">
          <div className="spacer">&nbsp;</div>
          <div id="title"><img src="./assets/julije_title.png" alt="Julije Knifer - The Game"/></div>
          <div className="spacer">&nbsp;</div>
        </div>
      </div>
  );
};

export default LandingHeader;
import { createSlice } from '@reduxjs/toolkit';
import RandomBoard from '../utils/RandomBoard';
import ClickBoard from '../utils/ClickBoard';

const boardSlice = createSlice({
  name: 'board',
  initialState: {
    gameCount: 1, // The number of games played
    boardSize: localStorage.getItem('boardSize') || "2", // Get the board size from local storage, or use a default values
    randomBoard: RandomBoard (localStorage.getItem('boardSize') || "2"), // Represents the state of the game board
    clickBoard: ClickBoard (localStorage.getItem('boardSize') || "2"), // Represents the state of the game board
    showHint: false, // Indicates whether to show a hint
  },
  reducers: {
    setShowHint: (state, action) => {
      // Update the board to show the hint
      state.showHint = action.payload;
    },
    removeHint: (state, action) => {
      // Update the board to remove the hint
      state.showHint = action.payload;
    },
    resetBoard: (state, action) => {
      // Reset the board to its active state
      state.randomBoard = RandomBoard (state.boardSize);
    },
    createNewBoard: (state, action) => {
      // Increase the matrix of the board for the next level
      //state.boardSize = parseInt(state.boardSize) + 1;
      
      if (state.gameCount===2 || state.gameCount===5 || state.gameCount===9 || state.gameCount===14 || state.game === 20 || state.game === 27) {
        state.boardSize = parseInt(state.boardSize) + 1;
      }
      state.gameCount++;
      state.randomBoard = RandomBoard (state.boardSize);
      state.clickBoard = ClickBoard (state.boardSize);
    },

    generateRandomBoard: (state, action) => {
      // Generate a new random board
      state.randomBoard = RandomBoard (state.boardSize);
    },
    setRandomBoard: (state, action) => {
      // Set the random board
      state.randomBoard = action.payload;
    },
    setBoardSize: (state, action) => {
      console.log("boardSlice.js.setBoardSize=", action.payload);
      localStorage.setItem('boardSize', action.payload); // Set the board size in local storage
      state.boardSize = action.payload;
    },
    setClickBoard: (state, action) => {
      const { y, x, value } = action.payload;
      // Ensure clickBoard is initialized and is an array of arrays
      if (!state.clickBoard) {
        state.clickBoard = [];
      }
      if (!state.clickBoard[y]) {
        state.clickBoard[y] = [];
      }
      state.clickBoard[y][x] = value;
    },
  },
});

export const { setShowHint, removeHint, resetBoard, createNewBoard, generateRandomBoard, setRandomBoard, setBoardSize, setClickBoard } = boardSlice.actions;

export default boardSlice.reducer;
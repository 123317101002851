/**
 * Generates a random board configuration for the game.
 * The board is a 2D array of size 'boardSize' x 'boardSize', where each cell is either 0 or 1.
 * 0 represents an empty cell, and 1 represents a cell with a cell.
 * The function ensures that at least one cell is present in the board.
 * 
 * @author Akos Papp 
 * @param {number} boardSize - The size of the board to generate.
 * @returns {Array} - The generated board configuration.
 *  
 */

function ClickBoard(boardSize){
    let myBoardSize = boardSize;
    //console.log("RandomBoard.myBoardSize=", myBoardSize);
    let myBoard = new Array(myBoardSize);
    for (let y = 0; y < myBoardSize; y++) {
        myBoard[y] = new Array(myBoardSize);
        for (let x = 0; x < myBoardSize; x++) {
            //console.log ("RandomBoard - ", y, ",", x, " = ", myBoard [x][y]);
            myBoard[y][x] = 0;
        }
    }
    return myBoard;
}

export default ClickBoard;
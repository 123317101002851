// App.js
import React from 'react';
import { Provider } from 'react-redux';
import store from './components/Store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/pages/LandingPage';
import ConfigPage from './components/pages/ConfigPage';
//import Board from './Board';
import Game from './components/Game';
import Board from './components/Board';
import GameOverPage from './components/pages/GameOverPage';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />   
          <Route path="/game" element={<Game />} />
          <Route path="/board" element={<Board />} />
          <Route path="/gameover" element={<GameOverPage />} />
          <Route path="/config" element={<ConfigPage />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
// ConfigPage.js
import React from 'react';
import ConfigHeader from '../elements/ConfigHeader';
import { useSelector } from 'react-redux';

const ConfigPage = () => {
  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  console.log("ConfigPage.js constructor gameHasStarted=", gameHasStarted);



  return (
    <div id="container">
      
      <ConfigHeader />

      <div id="main">
        <div id="matrix-container" >
            <h1>Config Page</h1>
        </div>
      </div>

      
      
    </div>
  );
};

export default ConfigPage;
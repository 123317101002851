import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const HeaderGame = () => {
  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  console.log("HeaderGame.js.gameHasStarted=", gameHasStarted);
  useEffect(() => {
    if (gameHasStarted) {
      // Call the function for when gameHasStarted is true
      console.log("HeaderGame.useEffect gameHasStarted=", gameHasStarted);
    } else {
      console.log("HeaderGame.useEffect gameHasStarted=", gameHasStarted);
    }
  }, [gameHasStarted]);

  return (
    <div id="header">
        <div className="header-content">
          <div className="spacer">&nbsp;</div>
          <a href="/">
            <div id="title"><img src="./assets/julije_title.png" alt="Julije Knifer - The Game"/></div>
          </a>
          <svg height="120" width="120">
              <circle key="timer" cx="60" cy="60" r="27" fill="black" />
          </svg>
        </div>
      </div>
  );
};

export default HeaderGame;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsTouching } from '../../data/gameSlice'; // Import the action
import gsap from 'gsap';

const FooterGame = ({randomBoard, clickBoard}) => {
//  const [isTouching, setIsTouching] = useState(false);
  const dispatch = useDispatch();
  const isTouching = useSelector(state => state.game.isTouching); // Access the state

  useEffect(() => {
    if (isTouching) {
      console.log('Touch started');
      for (let y = 0; y < randomBoard.length; y++) {
        for (let x = 0; x < randomBoard[y].length; x++) {
          if (randomBoard[y][x] === 1) {
            let cellName = `.rectangle-${y}-${x}`;
            let myDelay = 0;//Math.random(); // random delay between 0 and 1
            gsap.to(cellName, { fill: "#000000", duration: 0, delay: myDelay });
          }
          if (randomBoard[y][x] === 0 && clickBoard[y][x] === 1) {
            let cellName = `.rectangle-${y}-${x}`;
            let myDelay = 0;//Math.random(); // random delay between 0 and 1
            gsap.to(cellName, { fill: "#ffffff", duration: 0, delay: myDelay });
          }
        }
      }
    } else {
      console.log('Touch ended');
      for (let y = 0; y < randomBoard.length; y++) {
        for (let x = 0; x < randomBoard[y].length; x++) {
          if (randomBoard[y][x] === 1 && clickBoard[y][x] === 0) {
            let cellName = `.rectangle-${y}-${x}`;
            let myDelay = 0;//Math.random(); // random delay between 0 and 1
            gsap.to(cellName, { fill: "#ffffff", duration: 0, delay: myDelay });
          }
          if (clickBoard[y][x] === 1) {
            let cellName = `.rectangle-${y}-${x}`;
            let myDelay = 0;//Math.random(); // random delay between 0 and 1
            gsap.to(cellName, { fill: "#000000", duration: 0, delay: myDelay });
          }
        }
      }
    }
  }, [isTouching, randomBoard, clickBoard]); //

  const handleTouchStart = (e) => {
    //console.log("FooterGame.js.handleTouchStart 1 - isTouching=", isTouching);
    dispatch(setIsTouching(true));
    //console.log("FooterGame.js.handleTouchStart 2 - isTouching=", isTouching);
  };
  
  const handleTouchEnd = (e) => {
    e.preventDefault(); // Prevent the context menu from appearing
    if (isTouching) {
      dispatch(setIsTouching(false));
      //console.log("FooterGame.js.handleTouchEnd 1 - isTouching=", isTouching);
    } else {
      //console.log("FooterGame.js.handleTouchEnd 2 - isTouching=", isTouching);
    }
  };
/*
  const handleTouchMove = (e) => {
    if (isTouching) {
      //console.log("FooterGame.js.handleTouchMove 1 - isTouching=", isTouching);
    } else {
      //console.log("FooterGame.js.handleTouchMove 2 - isTouching=", isTouching);
    }
  };
*/
  function handleMouseOver() {
    for (let y = 0; y < randomBoard.length; y++) {
      for (let x = 0; x < randomBoard[y].length; x++) {
        if (randomBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = 0;//Math.random(); // random delay between 0 and 1
          gsap.to(cellName, { fill: "#000000", duration: 0, delay: myDelay });
        }
        if (randomBoard[y][x] === 0 && clickBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = 0;//Math.random(); // random delay between 0 and 1
          gsap.to(cellName, { fill: "#ffffff", duration: 0, delay: myDelay });
        }
      }
    }
  }

  function handleMouseOut() {
    for (let y = 0; y < randomBoard.length; y++) {
      for (let x = 0; x < randomBoard[y].length; x++) {
        if (randomBoard[y][x] === 1 && clickBoard[y][x] === 0) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = 0;//Math.random(); // random delay between 0 and 1
          gsap.to(cellName, { fill: "#ffffff", duration: 0, delay: myDelay });
        }
        if (clickBoard[y][x] === 1) {
          let cellName = `.rectangle-${y}-${x}`;
          let myDelay = 0;//Math.random(); // random delay between 0 and 1
          gsap.to(cellName, { fill: "#000000", duration: 0, delay: myDelay });
        }
      }
    }
  }

  return (
      <div id="footer">
        <div className="footer-content">
          <div className="spacer">&nbsp;</div>
          <svg 
              height="120" 
              width="120"
              onMouseOver={handleMouseOver} 
              onMouseOut={handleMouseOut}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              //onTouchMove={handleTouchMove}
          >
            <circle key="hint" cx="60" cy="60" r="27" fill="black" />
            {/*
            <foreignObject x="50" y="40" width="54" height="54">
              <img src="./assets/symbol_about.svg" alt="about the game" className="svg_icons" />
            </foreignObject>
            */}
          </svg>
        </div>
      </div>
  );
};

export default React.memo(FooterGame);
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGameHasStarted } from '../../data/gameSlice';
import { setShowHint } from '../../data/boardSlice';
import gsap from 'gsap';

const LandingFooter = () => {
  const dispatch = useDispatch();

  const gameHasStarted = useSelector(state => state.game.gameHasStarted);
  
  console.log("LandingFooter.js constructor gameHasStarted=", gameHasStarted);
  
  const hinting = useSelector(state => state.board.showHint);

  const movePlayButton = () => {
    console.log("LandingFooter.js.movePlayButton");
    let playBtnName = `#play`;
    let myDelay = 0.4;// + Math.random();
    let myDuration = 0.7;// + Math.random();
    let rotation = 0
    if(gameHasStarted){
      rotation = 180;
    }
    gsap.to(playBtnName, { rotation: rotation, duration: myDuration, delay: myDelay });
  }

  const moveHintButton = () => {
    console.log("LandingFooter.js.moveHintButton");
    let hintBtnName = `#hint`;
    let myDelay = 0.4;// + Math.random();
    let myDuration = 0.7;// + Math.random();
    let alpha = 0
    if(gameHasStarted){
      alpha = 1;
    }
    gsap.to(hintBtnName, { alpha: alpha, duration: myDuration, delay: myDelay });
  }

  const startGame = () => {
    console.log("LandingFooter.js.startGame");
    if(gameHasStarted){
      dispatch(setGameHasStarted(false));
    } else {
      dispatch(setGameHasStarted(true));
    }
  };

  const showHint = () => {
    console.log("LandingFooter.js.showHint");
    if(gameHasStarted){
      if(hinting){
        dispatch(setShowHint(false));
      } else {
        dispatch(setShowHint(true))
      }
    }
  };

  useEffect(() => {
    if (gameHasStarted) {
      // Call the function for when gameHasStarted is true
      console.log("LandingFooter.useEffect 1 gameHasStarted=", gameHasStarted);
      movePlayButton();
      moveHintButton();
    } else {
      console.log("LandingFooter.useEffect 2 gameHasStarted=", gameHasStarted);
      movePlayButton();
      moveHintButton();
    }
  }, [gameHasStarted]);

  return (
      <div id="footer">
        <div className="footer-content">
          <div className="spacer">&nbsp;</div>
          <svg id="play" height="120" width="120" onClick={startGame}>
              <circle cx="60" cy="60" r="27" fill="black" />
              <foreignObject x="52" y="47" width="54" height="54">
                <img src="./assets/symbol_play.svg" alt="play the game" className="svg_icons" />
              </foreignObject>
          </svg>
          <svg id="hint" height="120" width="120">
              <circle cx="60" cy="60" r="27" fill="black" onMouseOver={showHint} onMouseLeave={showHint} />
          </svg>
        </div>
      </div>
  );
};

export default LandingFooter;
/**
 * Generates a random board configuration for the game.
 * The board is a 2D array of size 'boardSize' x 'boardSize', where each cell is either 0 or 1.
 * 0 represents an empty cell, and 1 represents a cell with a cell.
 * The function ensures that at least one cell is present in the board.
 * 
 * @author Akos Papp 
 * @param {number} boardSize - The size of the board to generate.
 * @returns {Array} - The generated board configuration.
 *  
 */

function RandomBoard(boardSize){
    let myBoardSize = boardSize;
    //console.log("RandomBoard.myBoardSize=", myBoardSize);
    let myBoard = new Array(myBoardSize);
    let hasCell = false;
    for (let y = 0; y < myBoardSize; y++) {
        myBoard[y] = new Array(myBoardSize);
        for (let x = 0; x < myBoardSize; x++) {
            //console.log ("RandomBoard - ", y, ",", x, " = ", myBoard [x][y]);
            myBoard[y][x] = Math.round(Math.random());
            if (myBoard[y][x] === 1) {
                hasCell = true;
            }
        }
        console.log("RandomBoard [",y,"]=",myBoard[y]);
    }
    if (!hasCell) {
        console.log("RandomBoard - No cell found. Adding a cell.");
        let x = Math.round(Math.random(myBoardSize));
        let y = Math.round(Math.random(myBoardSize));
        console.log("RandomBoard - Adding a cell at ", x, ",", y);
        myBoard[y][x] = 1;
    }
    return myBoard;
}

export default RandomBoard;